var render = function render() {
  var _vm = this,
    _c = _vm._self._c;
  return _c("div", {
    staticClass: "user-group-users"
  }, [_c("div", {
    staticClass: "clearflex"
  }, [_c("el-button", {
    staticClass: "fl-right",
    attrs: {
      size: "small",
      type: "primary"
    },
    on: {
      click: _vm.authUserEnter
    }
  }, [_vm._v("Save")])], 1), _vm._v(" "), _c("el-transfer", {
    ref: "userTrans",
    staticStyle: {
      height: "100%"
    },
    attrs: {
      data: _vm.userTransferData,
      props: {
        key: "key",
        label: "label"
      },
      "filter-placeholder": "enter the key words",
      titles: ["Not Choiced", "Choiced Users"],
      filterable: "",
      "filter-method": _vm.filterMethod
    },
    model: {
      value: _vm.userTransferModel,
      callback: function callback($$v) {
        _vm.userTransferModel = $$v;
      },
      expression: "userTransferModel"
    }
  })], 1);
};
var staticRenderFns = [];
render._withStripped = true;
export { render, staticRenderFns };